import React from "react"

import Header from "./Header"
import Metadata from "./Metadata"

export default function Layout({ children }) {
    return (
        <div>
            <Metadata />
            <Header />
            {children}
        </div>
    )
}
