import React from "react"
import { Link } from "gatsby"
import { FormattedMessage as I18n } from "react-intl"
import styled from "@emotion/styled"

import Menu from "./Menu"
import LanguageSelector from "./LanguageSelector"

const CustomeHeader = styled.header`
    background-color: var(--dark);
    color: var(--light);
    padding: 20px 0 15px 0;
	position: fixed;
    z-index: 100;
	left: 0;
	right: 0;
    top: 0;
    display: block;

	& > .container {
        justify-content: space-between;
        padding-left: 8vw;
        padding-right: 8vw;
    }
`

const Mobile = styled.span`
    @media screen and (max-width: 768px) {
        display: none
    }
`

export default function Header() {
    return (
        <CustomeHeader>
            <div className="container o-line-in">
                <div>
                    <Link to="/">
                        <h1 style={{ fontWeight: 800, fontSize: "20px", color: "var(--light)" }}>
                            <I18n id="header.title" defaultMessage={"default"} />
                            <Mobile className="mobile">&nbsp;<I18n id="header.mobile" defaultMessage={"default"} /></Mobile>
                        </h1>
                    </Link>
                </div>
                <div className="container o-line-in">
                    <Mobile className="langue"><LanguageSelector /> </Mobile>
                    <Menu />
                </div>
            </div>
        </CustomeHeader>
    )
}
