import React from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { useStaticQuery, graphql } from "gatsby"

import styled from "@emotion/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { faInstagram, faFacebook, faWhatsapp } from "@fortawesome/free-brands-svg-icons"

const Link_Container = styled.div`
    display: flex;
    justify-content: center;
    margin: 40px 0 40px 0;
    width: 100%;

    a {
        position: relative;
        color: inherit;
        font-size: 40px;
        margin: 1rem;

        & > .o-icon {
            display: inline-block;
            vertical-align: middle;
            box-sizing: inherit;
            font-size: inherit;
        }

        & > .o-icon-circle {
            width: calc(100% + 1.5rem);
            height: calc(100% + 1.5rem);
            position: absolute;
            left: -0.75rem;
            top: -0.6rem;

            circle {
                fill: none;
                stroke-width: 2px;
                stroke: currentColor;
                stroke-linecap: round;
                stroke-dasharray: 88;
                stroke-dashoffset: 88;
                transform: rotate(-180deg);
                transform-origin: 16px 16px;
                transition: stroke-dashoffset 0.5s, transform 0.4s;
            }

            &:hover {
                circle {
                    stroke-dashoffset: 0;
                    transform: rotate(0deg);
                }
            }
        }
    }
`

export default function SocialLinks() {
    const data = useStaticQuery(
        graphql`
            query {
                dataJson {
                    contacts {
                        instagram
                        facebook
                        whatsapp
                        mail
                    }
                }
            }
        `
    )

    const contacts = data.dataJson.contacts

    return (
        <Link_Container>
            <OutboundLink aria-label={`${contacts.instagram} Instagram`} href={`https://www.instagram.com/${contacts.instagram}/`} rel="noreferrer" target="_blank">
                <FontAwesomeIcon icon={faInstagram} className="o-icon" />
                <svg className="o-icon-circle" viewBox="0 0 32 32">
                    <circle cx="16" cy="16" r="14"></circle>
                </svg>
            </OutboundLink>
            <OutboundLink aria-label={`${contacts.facebook} Facebook page`} href={`https://www.facebook.com/${contacts.facebook}/`} rel="noreferrer" target="_blank">
                <FontAwesomeIcon icon={faFacebook} className="o-icon" />
                <svg className="o-icon-circle" viewBox="0 0 32 32">
                    <circle cx="16" cy="16" r="14"></circle>
                </svg>
            </OutboundLink>
            <OutboundLink aria-label={"Whatsapp contact"} href={`https://api.whatsapp.com/send?phone=${contacts.whatsapp}`} rel="noreferrer" target="_blank">
                <FontAwesomeIcon icon={faWhatsapp} className="o-icon" />
                <svg className="o-icon-circle" viewBox="0 0 32 32">
                    <circle cx="16" cy="16" r="14"></circle>
                </svg>
            </OutboundLink>
            <OutboundLink aria-label={"mail contact"} href={`mailto:${contacts.mail}`} rel="noreferrer">
                <FontAwesomeIcon icon={faEnvelope} className="o-icon" />
                <svg className="o-icon-circle" viewBox="0 0 32 32">
                    <circle cx="16" cy="16" r="14"></circle>
                </svg>
            </OutboundLink>
        </Link_Container>
    )
}
